<template>
  <div
    class="w-100 searchpanel"
    data-testid="searchPanel"
  >
    <div>
      <label for="search" class="visually-hidden">
        {{ $t('Search') }}
      </label>
      <div class="search-input-group col-xs-12 px15">
        <input
          ref="search"
          id="search"
          v-model="search"
          @input="makeSearch"
          @blur="$v.search.$touch()"
          @keyup.enter="toggleQueryFilter"
          class="search-panel-input col-xs-12"
          :placeholder="$t('Type what you are looking for...')"
          type="text"
        >
        <i class="icon-microphone voice-search-icon col-xs-1" :class="{'cl-main': btnStop}" @click="startRecording" />
        <i class="icon-wyszukiwarka search-icon col-xs-1" @click="toggleQueryFilter" />
        <i class="icon-zamknij ipad-icon" @click="closeSearchpanel" />
      </div>
      <span class="cl-main notification px15" v-if="getNoResultsMessage !== ''">{{ getNoResultsMessage }}</span>
    </div>
    <transition name="fade">
      <div class="container search-layer search-layer-voice flex bg-cl-primary middle-xs" v-show="btnStop">
        <div class="flex flex-col middle-xs">
          <div class="lds-facebook">
            <div />
            <div />
            <div />
            <div />
          </div>
          <b class="h5 cl-black">{{ $t('Speak now') }}</b>
        </div>
        <div class="listening">
          <p class="listening--text-result m0 pl5 cl-black">
            {{ textResult }}
          </p>
          <i class="cancel icon-zamknij" @click="stopRecording" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container search-layer bg-cl-primary" v-show="search.length && visibleProducts.length">
        <button
          v-show="search.length && visibleProducts.length"
          @click="toggleQueryFilter"
          class="button__show-all pointer weight-700"
        >
          {{ $t('Show all results') }}
        </button>
        <div class="categories">
          <category-panel :categories="categories" v-model="selectedCategoryIds" />
        </div>
        <div class="product-listing row mt20">
          <product-tile
            class="update-add-to-cart"
            v-for="(product, index) in visibleProducts"
            :key="product.id"
            :product="product"
            :position="index"
            list="search"
            @click.native="closeSearchpanel"
            :omnibus-price="omnibusPriceMap[product.sku]"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel'
import ProductTile from 'theme/components/core/ProductTile'
import VueOfflineMixin from 'vue-offline/mixin'
import CategoryPanel from 'theme/components/core/blocks/Category/CategoryPanel'
import { minLength } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'
import { mapGetters } from 'vuex'
import config from 'config'
import openSocket from 'socket.io-client';

// ================= CONFIG =================
// Stream Audio
let bufferSize = 2048;
let AudioContext;
let context;
let processor;
let input;
let globalStream;

// lets
let streamStreaming = false;

// audioStream constraints
const constraints = {
  audio: true,
  video: false,
  socket: null
};

export default {
  mixins: [SearchPanel, VueOfflineMixin],
  validations: {
    search: {
      minLength: minLength(3)
    }
  },
  components: {
    ProductTile,
    CategoryPanel
  },
  data () {
    return {
      search: '',
      selectedCategoryIds: [],
      textResult: '',
      finalResult: '',
      btnStop: false
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    isToggleSearch () {
      return (((this.visibleProducts.length && this.categories.length > 1) && this.search.length > 1) && this.toogleSearchModal)
    },
    visibleProducts () {
      const productList = this.products || []
      if (this.selectedCategoryIds.length) {
        return productList.filter(product => product.category_ids && product.category_ids.some(categoryId => {
          const catId = parseInt(categoryId)
          return this.selectedCategoryIds.includes(catId)
        }))
      }
      return productList
    },
    categories () {
      const categoriesMap = {}
      this.products.forEach(product => {
        if (product.category) {
          return [...product.category].forEach(category => {
            categoriesMap[category.category_id] = category
          })
        }
      })
      return Object.keys(categoriesMap).map(categoryId => categoriesMap[categoryId])
    },
    // mainCategory () {
    //   return this.getMenuCategories.find(c => c.id === config.entities.category.categoriesRootCategorylId)
    // },
    getNoResultsMessage () {
      let msg = ''
      if (!this.$v.search.minLength) {
        msg = 'Searched term should consist of at least 3 characters.'
      } else if (this.emptyResults) {
        msg = 'No results were found.'
      }
      return this.$t(msg)
    }
  },
  methods: {
    getVoiceResult (result) {
      if (result) {
        this.search = result;
        this.makeSearch();
      }
    },
    startRecording () {
      this.clearVoiceResults();
      this.initRecording();
      this.btnStop = true;
      setTimeout(() => {
        this.finalResult = this.textResult;
        this.stopRecording();
      }, 5000);
    },
    initRecording () {
      this.socket.emit('startGoogleCloudStream', ''); // init socket Google Speech Connection
      streamStreaming = true;
      AudioContext = window.AudioContext || window.webkitAudioContext;
      context = new AudioContext();
      processor = context.createScriptProcessor(bufferSize, 1, 1); // createScriptProcessor() OUTDATED AND UNSUPPORTED should be replaced by audioWorklet
      processor.connect(context.destination);

      let handleSuccess = stream => {
        globalStream = stream;
        input = context.createMediaStreamSource(stream);
        input.connect(processor);

        processor.onaudioprocess = e => {
          this.microphoneProcess(e);
        };
      };

      // ================= POLYFILL =================
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // Some browsers partially implement mediaDevices.
      // adding the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        }
      }
      navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(err => err);
    },
    microphoneProcess (e) {
      let left = e.inputBuffer.getChannelData(0); // inheriting form AudioContext.createScriptProcessor() OUTDATED AND UNSUPPORTED
      let left16 = this.downsampleBuffer(left, 44100, 16000);
      this.socket.emit('binaryData', left16);
    },
    downsampleBuffer (buffer, sampleRate, outSampleRate) {
      if (outSampleRate === sampleRate) {
        return buffer;
      }
      if (outSampleRate > sampleRate) {
        return false;
      }
      let sampleRateRatio = sampleRate / outSampleRate;
      let newLength = Math.round(buffer.length / sampleRateRatio);
      let result = new Int16Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;
      while (offsetResult < result.length) {
        let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        let accum = 0;
        let count = 0;
        for (
          let i = offsetBuffer;
          i < nextOffsetBuffer && i < buffer.length;
          i++
        ) {
          accum += buffer[i];
          count++;
        }

        result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
      }
      return result.buffer;
    },
    stopRecording () {
      if (!streamStreaming) {
        this.clearVoiceResults();
        return Promise.resolve(false);
      } // stop disconnecting if already disconnected
      this.btnStop = false;
      streamStreaming = false;
      this.socket.emit('endGoogleCloudStream', '');

      let track = globalStream.getTracks()[0];
      track.stop();

      input.disconnect(processor);
      processor.disconnect(context.destination);

      if (this.finalResult && this.finalResult.length > 0) {
        this.getVoiceResult(this.finalResult);
      }

      this.clearVoiceResults();

      return context.close().then(() => {
        input = null;
        processor = null;
        context = null;
        AudioContext = null;
        return true;
      });
    },
    clearVoiceResults () {
      this.textResult = '';
      this.finalResult = '';
    },
    toggleQueryFilter () {
      if (this.getNoResultsMessage === '') {
        this.$router.push({ path: '/' + 'sklep', query: { query: this.search } })
        this.search = ''
        this.$store.commit('ui/setSidebar', false)
      }
    }
  },
  created () {
    this.socket = openSocket(
      config.apiKeys.voiceSearch
    );
    // ================= SOCKET IO =================
    this.socket.on('connect', data => {
      this.socket.emit('join', 'Server Connected to Client');
    });

    this.socket.on('speechData', data => {
      let dataFinal = undefined || data.results[0].isFinal;
      if (dataFinal === false) {
        this.textResult = data.results[0].alternatives[0].transcript;
      } else if (dataFinal === true) {
        let finalString = data.results[0].alternatives[0].transcript;
        this.textResult = finalString;
        this.finalResult = finalString;
        this.stopRecording();
      }
    });

    // ================= OTHER STUFF =================

    window.onbeforeunload = () => {
      if (streamStreaming) {
        this.socket.emit('endGoogleCloudStream', '');
      }
    };
  },
  beforeDestroy () {
    this.socket.close();
  },
  mounted () {
    // this.$refs.search.focus()
  },
  watch: {
    categories () {
      this.selectedCategoryIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 15px;
  margin-bottom: 30px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #1396EC;
  border-radius: 15px;
  height: 20px;
  width: 5px;
  opacity: 0.5;
  animation: lds-facebook 1s ease infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0px;
  animation-delay: -0.36s;
}
.lds-facebook div:nth-child(2) {
  left: 10px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(3) {
  left: 20px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(4) {
  left: 30px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0;
    opacity: 0.5;
  }
  50% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0.5;
  }
}
.cancel {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 18px;
}
.search-input-group {
    position: relative;
}
.voice-search-icon {
    position: absolute;
    top: 50%;
    right: 20%;
    font-size: 26px;
    transform: translate(0, -50%);
}
.search-icon {
    position: absolute;
    top: 50%;
    right: 10%;
    font-size: 35px;
    transform: translate(0, -50%);
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .voice-search-icon {
    right: 15%;
  }
  .search-icon {
    right: 9%;
  }
}

.search-panel-input {
    position: relative;
    outline: none;
    border: 1px solid #CFCFCF;
    width: 100%;
    border-radius: 25px;
    font-size: 16px;
    padding-left: 20px;
    vertical-align: middle;
}
.notification {
    font-size: 12px;
}
</style>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import "~theme/css/variables/grid";
@import "~theme/css/variables/typography";

.searchpanel {
  .search-layer {
    box-shadow: -4px 4px 8px -4px #808080;
    -webkit-box-shadow: -4px 4px 8px -4px #808080;
    max-height: calc(100% - 46px);
    top: 79px;
    position: fixed;
    overflow-y: scroll;
    bottom: auto;
    overflow-y: auto;
    z-index: 3;
    right: 0;
    max-width: 740px;
    width: 100%;
    padding-top: 10px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      max-width: 100%;
    }
  }
  .search-layer-voice {
    z-index: 10 !important;
    top: 75px !important;
    border-radius: 25px;
    padding-bottom: 10px;
  }
  .close-icon-row {
    display: flex;
    justify-content: flex-end;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;

    @media #{$media-xs} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .row {
    margin-left: - map-get($grid-gutter-widths, lg) / 2;
    margin-right: - map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      margin-right: - map-get($grid-gutter-widths, xs) / 2;
      margin-left: - map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .col-md-12 {
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .product-listing {
    padding-bottom: 110px;
  }

  .product {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33%;
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 25%;
    }
    @media #{$media-xs} {
      width: 50%;
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }
  .ipad-icon {
    display: none;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      position: absolute;
      display: inline-block;
      top: 50%;
      right: 4%;
      font-size: 35px;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }
}

.button__show-all {
    position: fixed;
    left: 0;
    bottom: 60px;
    right: 0;
    z-index: 1000;
    background-color: #1396EC;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 10px 15px;
    width: 90%;
    margin: 0 auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-in {
  &-left-enter-active,
  &-right-enter-active{
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  &-left-leave-active,
  &-right-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0.0, 1, 1);
  }
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #1396EC;
  border-radius: 15px;
  border: none;
}
</style>
